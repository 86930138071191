<template>
  <div>
    <AppModal
      :dialogTitle="dialogTitle"
      :disableClose="true">
      <b-form @submit="editFilter">
        <b-form-group
          label="Filter Name"
          label-for="filterName"
          :label-cols="3">
          <b-form-input
            id="filterName"
            type="text"
            aria-describedby="filterName-input"
            placeholder="Enter Filter Name"
            autocomplete="off"
            @input="$v.form.filterName.$touch()"
            v-model.trim="$v.form.filterName.$model"
            :state="!$v.form.filterName.$error">
          </b-form-input>
          <b-form-invalid-feedback id="filterName-input">
            <span v-if="!$v.form.filterName.required">
              Filter Name is required.
            </span>
            <span
              v-if="!$v.form.filterName.uniqueFilterName &&
                $v.form.filterName.required">
              Filter Name must be unique.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Trigger"
          label-for="class"
          :label-cols="3">
          <select class="form-control" v-model="form.trigger">
            <option
              v-for="(value, key) in triggerOptions"
              :key="key"
              :value="value.id">
              {{ value.label }}
            </option>
          </select>
        </b-form-group>

        <b-form-group
          label="Premium"
          label-for="premiumType"
          :label-cols="3">
          <toggle-button
            class="large-font"
            id="premiumType"
            v-model="form.premiumType"
            :width="110"
            :height="35"
            :labels="{
              checked : 'YES',
              unchecked : 'NO',
            }"
          />
        </b-form-group>

        <b-form-group
          label="Class"
          label-for="class"
          :label-cols="3">
          <toggle-button
            class="large-font"
            id="class"
            v-model="form.class"
            :width="110"
            :height="35"
            :labels="{
              checked   : 'Filter/FX',
              unchecked : 'Mask',
            }"
          />
        </b-form-group>

        <b-form-group
          label="Publish"
          label-for="publish"
          :label-cols="3">
          <toggle-button
            class="large-font"
            id="publish"
            v-model="form.publish"
            :width="110"
            :height="35"
            :labels="{
              checked   : 'YES',
              unchecked : 'NO',
            }"
          />
        </b-form-group>

        <b-row no-gutters>
          <b-col cols="9">
            <b-form-group
              label="Filter File"
              label-for="filterFile"
              :label-cols="4">
              <b-form-file
                id="filterFile"
                aria-describedby="filter-file"
                placeholder="Choose a file..."
                v-model="form.filterFile"
              ></b-form-file>
            </b-form-group>
            <b-form-group
              label="Filter Icon"
              label-for="filterIcon"
              :label-cols="4">
              <b-form-file
                id="filterIcon"
                aria-describedby="filter-icon"
                placeholder="Choose a file..."
                v-model="form.filterIcon"
                accept="image/jpeg, image/png"
                @change="previewImage"
              ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col class="text-center" cols="3">
            <div class="text-center" v-if="!previewIcon">
              <div v-if="currentIcon !==''">
                <img
                  class="thumbnail"
                  :src="currentIcon"
                  alt="thumbnail"
                  width="100"
                  height="100"
                  title="Current Icon"
                />
                <p class="mb-0 small caption">Current Icon</p>
              </div>
              <span v-else>Not Available</span>
            </div>
            <div class="text-center" v-else>
              <img
                class="thumbnail"
                :src="previewIcon"
                width="100"
                height="100"
                alt="Preview Icon"
                title="Preview"
              />
              <p class="mb-0 small caption">Preview</p>
            </div>
          </b-col>
        </b-row>

        <div class="d-flex justify-content-end mt-3">
          <b-button
            class="text-center m-1"
            type="submit"
            variant="outline-success"
            :disabled="$v.form.$invalid || !isFormEdited">
            Submit
          </b-button>
          <b-button
            class="text-center m-1"
            variant="outline-danger"
            @click="$emit('close')">
            Cancel
          </b-button>
        </div>
      </b-form>
    </AppModal>

    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>
  import _ from 'lodash';
  import { required } from 'vuelidate/lib/validators';

  export default {
    props : {
      data : {
        type     : Object,
        required : true,
      },
    },
    data() {
      return {
        loading     : false,
        dialogTitle : 'Edit Filter',
        form        : {
          filterName  : this.data.name,
          premiumType : Boolean(this.data.subtype),
          filterFile  : null,
          filterIcon  : null,
          class       : (this.data.class == 'Filter/Effects') ? true : false,
          trigger     : this.data.triggerId,
          publish     : (this.data.published == 'yes')? true : false,
          mFilterId   : this.data.mobileFilterId,
        },
        isFormEdited     : false,
        uniqueFilterName : true,
        previewIcon      : null,
        currentIcon      : this.data.iconUrl,
        triggerOptions   : [
          { id : 1, label : 'None'},
          { id : 2, label : 'Open Mouth'},
          { id : 3, label : 'Tap Screen'},
        ],
      }
    },
    components : {
      'AppModal'       : () => import('@/components/layout/AppModal'),
      'AppLoaderAdmin' : () => import('@/components/layout/AppLoaderAdmin'),
    },
    watch : {
      'form.filterName'(val) {
        if (val && val !== this.data.name)
          this.checkFilterName(val);
      },
    },
    methods : {

      /**
       * Edit Filter
       */
      async editFilter(e) {
        e.preventDefault();
        this.loading = true;
        const formData = new FormData();
        const published = (this.data.published == 'yes') ? true : false;
        const classVal = (this.data.class == 'Filter/Effects') ? true : false;
        const params = {};

        // Filter File and Icon param
        if (this.form.filterIcon)
          formData.append('filterIcon', this.form.filterIcon);
        if (this.form.filterFile)
          formData.append('filterFile', this.form.filterFile);

        // Data params
        if (this.form.filterName !== this.data.name)
          params.name = this.form.filterName;
        if (this.form.premiumType !== Boolean(this.data.subtype))
          params.subscription_type = (this.form.premiumType) ? 1 : 0;
        if (this.form.trigger !== this.data.triggerId)
          params.trigger_type = (this.form.trigger) ? this.form.trigger : 1;
        if (this.form.class !== classVal)
          params.class = (this.form.class) ? 1 : 0;
        if (this.form.publish !== published)
          params.published = (this.form.publish) ? 'yes' : 'no';

        formData.append('mFilterAssetsId', this.data.mobileFilterId);
        formData.append('updateFields', JSON.stringify(params));

        try {
          const result = await this.$http.put('api/filter', formData, {});

          if (result) {
            this.loading = false;
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success!',
              text  : `Edited ${this.form.filterName} filter successfully.`,
            });

            this.$parent.$emit('reload-table');
            this.$emit('close');
          }
        } catch(err) {
          this.loading = false;
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }
      },

      /**
       * Check if Filter name is unique
       */
      checkFilterName : _.debounce(function(name) {
        this.$http.get('api/checkFilterName', {
          params : {
            filterName : name,
          },
        }).then(response => {
          this.uniqueFilterName = (response.data) ? false : true;
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      }, 500),

      /**
       * Preview Image
       * @param event
       */
      previewImage(event) {
        const input = event.target;
        if (input.files) {
          const reader = new FileReader();
          reader.onload = (e) =>
            this.previewIcon = e.target.result;
          this.form.filterFile = input.files[0];
          reader.readAsDataURL(input.files[0]);
        } else
          this.preview = null;
      },

      /**
       * Check if Form is edited
       */
      formUpdated() {
        this.isFormEdited = false;
        const published = (this.data.published == 'yes') ? true : false;
        const classVal = (this.data.class == 'Filter/Effects') ? true : false;

        if (this.form.filterName !== this.data.name ||
          this.form.premiumType !== Boolean(this.data.subtype) ||
          this.form.trigger !== this.data.triggerId ||
          this.form.publish !== published ||
          this.form.class !== classVal ||
          this.form.filterFile)
          this.isFormEdited = true;
      },
    },
    created() {
      this.$watch('form', this.formUpdated, {
        deep : true,
      });
    },
    validations : {
      form : {
        filterName : {
          required,
          uniqueFilterName() {
            return this.uniqueFilterName;
          },
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/settings";

  .caption {
    font-size: 1em !important;
  }

  .thumbnail {
    background-color: rgba(255,255,255,0.5);
    border: 1px dashed $primary-color;
  }
</style>
